<template>
  <v-row>
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn class="ml-2" @click="dialogTemplate=true" outlined>
          <v-icon left>mdi-file-document-edit </v-icon>Plantillas personalizadas
        </v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12">
      <v-row class="ma-0 pa-0">
        <v-col cols="3" class="pt-0" v-for="(t, i) in 4" :key="t">
          <v-hover v-slot="{ hover }">
            <v-card :to="{name: 'TemplatePersonalization', params: {id: `template-${i+1}`}}" :elevation="hover ? 4 : 0" flat>
              <v-img :src="require(`@/assets/product-outbound/templates/template${t}.svg`)" :min-height="200" />
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <!-- campaign by send -->
      <v-col class="mt-2">
        <span class="d-flex align-center subtitle-1 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-send-clock</v-icon>Campañas por enviar</span>
        <v-row class="ma-0 pa-0 mt-5">
          <v-col cols="6" class="py-0 pl-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-7.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      ¡Celebra el Verano con Ofertas Frescas y Sabrosas!
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 01 de mar de 2024 hasta el 31 de may 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="10" class="text-right">
                          <v-btn class="ml-2" @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})" outlined>
                            <v-icon left>mdi-pencil </v-icon>Editar plantilla
                          </v-btn>
                          <v-btn class="ml-2" @click="dialogConfirmSendCampaign=true" color="primary">
                            <v-icon left>mdi-send </v-icon>Enviar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="6" class="py-0 pr-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-3.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      ¡Celebra el Verano con Ofertas Frescas y Sabrosas!
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 01 de mar de 2024 hasta el 31 de may 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="10" class="text-right">
                          <v-btn class="ml-2" @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})" outlined>
                            <v-icon left>mdi-pencil </v-icon>Editar plantilla
                          </v-btn>
                          <v-btn class="ml-2" @click="dialogConfirmSendCampaign=true" color="primary">
                            <v-icon left>mdi-send </v-icon>Enviar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <!--end campaign by send -->

      <!-- active campaigns -->
      <v-col class="mt-2">
        <span class="d-flex align-center subtitle-1 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-send-check</v-icon>Campañas activas</span>
        <v-row class="ma-0 pa-0 mt-5">
          <v-col cols="6" class="py-0 pl-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-1.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      Solo con los verdaderos precios bajos, llenas así tu carrito ¡Y sin ofertas!
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 01 de mar de 2024 hasta el 31 de may 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="11" class="text-right">
                          <v-btn @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})" outlined>
                            <v-icon left>mdi-sync </v-icon>Reutilizar campaña
                          </v-btn>
                          <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5RrK4' }}" outlined>
                            <v-icon left>mdi-chart-timeline-variant </v-icon>Estadísticas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="6" class="py-0 pr-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-2.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      Marzo Fresco en PlazaVea: Descubre Nuestras Ofertas de Fin de Temporada
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 01 de mar de 2024 hasta el 31 de may 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="11" class="text-right">
                          <v-btn outlined @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})">
                            <v-icon left>mdi-sync </v-icon>Reutilizar campaña
                          </v-btn>
                          <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5RrK5' }}" outlined>
                            <v-icon left>mdi-chart-timeline-variant </v-icon>Estadísticas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-5">
          <v-col cols="6" class="py-0 pl-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-6.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      Verano Vibrante en PlazaVea: Descubre Nuestras Promociones Especiales
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 14 de dic de 2023 hasta el 25 de dic 2023
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="11" class="text-right">
                          <v-btn outlined @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})">
                            <v-icon left>mdi-sync </v-icon>Reutilizar campaña
                          </v-btn>
                          <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5RrK1' }}" outlined>
                            <v-icon left>mdi-chart-timeline-variant </v-icon>Estadísticas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="6" class="py-0 pr-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-8.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      ¡Celebra el Verano con Ofertas Frescas y Sabrosas!
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 01 de mar de 2024 hasta el 31 de may 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="11" class="text-right">
                          <v-btn outlined @click="$router.push({ name: 'EditTemplate', params: { id: 'template-1'}})">
                            <v-icon left>mdi-sync </v-icon>Reutilizar campaña
                          </v-btn>
                          <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5RrK3' }}" outlined>
                            <v-icon left>mdi-chart-timeline-variant </v-icon>Estadísticas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <v-col class="mt-5 text-center">
          <v-btn @click="$router.push({ name: 'ActiveCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5RrK1' } })" outlined>Ver todas las campañas</v-btn>
        </v-col>
      </v-col>
      <!--end active campaigns -->

      <!-- active campaigns -->
       <v-col class="mt-0">
        <span class="d-flex align-center subtitle-1 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-Email-Arrow-Left</v-icon>Campañas anteriores</span>
        <v-row class="ma-0 pa-0 mt-5">
          <v-col cols="6" class="py-0 pl-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-4.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      PlazaVea en San Valentín: Encuentra el Regalo Perfecto con Nuestras Ofertas Especiales
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 01 de feb de 2024 hasta el 14 de feb 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="11 " class="text-right">
                          <v-btn outlined>
                            <v-icon left>mdi-sync </v-icon>Reutilizar campaña
                          </v-btn>
                          <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5Rru2' }}" outlined>
                            <v-icon left>mdi-chart-timeline-variant </v-icon>Estadísticas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="6" class="py-0 pr-0">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 4 : 0" flat>
                <v-row no-gutters align="center">
                  <v-col cols="4" class="pa-4 pb-0">
                    <v-card class="rounded-b-0 elevation-3 rounded-sm">
                      <v-img :height="180" :src="require(`@/assets/product-outbound/examples-templates/img-5.png`)" />
                    </v-card>
                  </v-col>
                  <v-col class="px-3 py-5 mt-1">
                    <span class="d-block font-weight-semibold subtitle-2">
                      Carnaval de Ahorros en PlazaVea: Descubre Nuestras Promociones para la Fiesta
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-file-document</v-icon>
                      Boletas electrónicas
                    </span>
                    <span class="d-flex align-center mt-3 fontDraw--text body-2">
                      <v-icon class="mr-2" size="16" color="fontDraw">mdi-calendar-blank</v-icon>
                      Desde el 15 de feb de 2023 hasta el 31 de ene 2024
                    </span>
                    <v-col cols="12" class="px-0 mt-1">
                      <v-row>
                        <v-spacer />
                        <v-col cols="11" class="text-right">
                          <v-btn outlined>
                            <v-icon left>mdi-sync </v-icon>Reutilizar campaña
                          </v-btn>
                          <v-btn class="ml-2" :to="{name: 'StatisticsCampaignList', params: { id: 'ca_qEmqLQwAHuU3R5R233' }}" outlined>
                            <v-icon left>mdi-chart-timeline-variant </v-icon>Estadísticas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <!--end active campaigns -->
    </v-col>

    <!-- dialog send campaign confirm -->
    <v-dialog v-model="dialogConfirmSendCampaign" width="838" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Enviar campaña</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmSendCampaign=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-send-campaing.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              <span class="d-block body-1">Estás a punto de <span class="font-weight-semibold">enviar esta campaña con el documento y fecha seleccionados.</span></span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />

              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-4" :height="50" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2" style="line-height: 14px;">Esta acción
                      <span class="font-weight-bold">podría tardar varios minutos</span> en completarse.
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirmSendCampaign=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogConfirmSendCampaign=false" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog send campaign -->

    <!-- dialog template -->
    <v-dialog v-model="dialogTemplate" width="958" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Plantillas personalizadas</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogTemplate=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5 px-5 fontBody--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-template.svg`)" :width="228" />
            </v-col>
            <v-col class="pr-4 body-1 pl-10">
              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2 align-center" style="line-height: 14px;">Estás a punto de solicitar una
                      <span class="font-weight-bold">plantilla personalizada.</span> Una vez que hayas hecho la solicitud, nuestro equipo de
                      <span class="font-weight-bold">soporte se pondrá en contacto contigo</span> una vez que la <span class="font-weight-bold">plantilla esté lista.</span>
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
              <div class="mt-5">
                <span class="d-block mt-3 body-1 fontBody--text font-weight-bold mb-2">Sube tu diseño para que Axteroid la cree por ti</span>
                <v-list class="transparent v-list-form-extended" max-width="100%">
                  <v-list-item class="py-0">
                    <v-list-item-title>
                      Subir archivo <v-icon color="secondary" x-small>mdi-information</v-icon>
                    </v-list-item-title>
                    <v-list-item-subtitle class="ma-0 pa-0 pl-3">
                      <v-file-input class="ml-n1 custom-file" v-model="file" ref="file" type="file" accept=".jpg" background-color="transparent" hide-details solo flat chips style="z-index: 10" />
                      <span class="mt-2 mx-auto text-center transparent custom-text-file" v-if="!file" style="position: absolute; top:10px; z-index: 0; left: 200px; right: 50px">
                        <v-icon size="36" color="secondary">mdi-cloud-upload-outline</v-icon>
                        <span class="d-block my-1 body-1 fontBody--text font-weight-semibold">Haz click para seleccionar un archivo </span>
                        <span class="d-block body-2">o arrastra el archivo aquí (.jpg)</span>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-divider class="mt-8 mb-5" />
                </v-list>
                <span class="d-block mb-4 body-1 fontBody--text font-weight-bold mb-2">Reglas de uso del la campaña</span>
                <v-row no-gutters>
                  <v-col cols="5" class="pr-2">
                    <span class="d-block">Tipo de documento</span>
                    <v-autocomplete label="Selecciona un tipo de documento" :items="documentTypesList.filter(({country}) => country === $store?.state?.auth?.account?.country )" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details>
                      <template v-slot:item="data">
                        <v-list-item-title class="font-weight-regular px-2">{{data.item.name}}</v-list-item-title>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="px-2">
                    <span class="d-block">Fecha de inicio</span>
                    <v-menu v-model="menu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                      </template>
                      <v-date-picker v-model="date" @input="menu=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="primary" />
                    </v-menu>
                  </v-col>
                  <v-col class="pr-0 pl-2">
                    <span class="d-block">Fecha de término <v-chip class="ml-3 px-1" x-small color="off">Opcional</v-chip></span>
                    <v-menu v-model="menu1" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date1" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                      </template>
                      <v-date-picker v-model="date1" @input="menu1=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="primary" />
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogTemplate=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogTemplate=false">Solicitar plantilla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog template -->
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import documentTypesList from '@/collections/documentTypes'
import ModelTemplates from '@/modules/outbound/templates/components/templates/Template1'

export default {
  components: {
    VEmptyState,
    MiniHeader,
    ModelTemplates
  },
  data: () => ({
    menu: false,
    date: null,
    date1: null,
    menu1: false,
    documentTypesList: documentTypesList,
    file: null,
    dialogTemplate: false,
    dialogConfirmSendCampaign: false,
    confirm: '',
    breadcrumbs: {
     main: 'Plantillas',
     children: [{ text: 'Campañas' }]
   }
  })
}
</script>